/* eslint-disable no-undef */
import React, { createContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';  // Import PropTypes
import Keycloak from 'keycloak-js';
import { PageLoader } from './Loader';

export const KeycloakContext = createContext();

const keycloak = new Keycloak({
  url: `${process.env.REACT_APP_KAYCLOCK_URL}`,
  realm: `${process.env.REACT_APP_KAYCLOCK_REALM}`,
  clientId: `${process.env.REACT_APP_KAYCLOCK_CLIENT}`,
});

export const KeycloakProvider = ({ children }) => {
  const [keycloakInstance, setKeycloakInstance] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const memoizedValue = useMemo(() => ({ keycloakInstance, authenticated }), [keycloakInstance, authenticated]);
  useEffect(() => {
    keycloak.init({ onLoad: 'login-required' }).then(auth => {
      setKeycloakInstance(keycloak);
      setAuthenticated(auth);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <PageLoader/>;
  }
  return (
    <KeycloakContext.Provider value={memoizedValue}>
      {children}
    </KeycloakContext.Provider>
  );
};

KeycloakProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default KeycloakProvider;
